.visible {
    visibility: visible
}
.fixed {
    position: fixed
}
.relative {
    position: relative
}
.top-1 {
    top: 0.25rem
}
.float-right {
    float: right
}
.m-0 {
    margin: 0px
}
.my-0 {
    margin-top: 0px;
    margin-bottom: 0px
}
.my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem
}
.mt-4 {
    margin-top: 1rem
}
.mt-2 {
    margin-top: 0.5rem
}
.mt-6 {
    margin-top: 1.5rem
}
.mt-10 {
    margin-top: 2.5rem
}
.mb-2 {
    margin-bottom: 0.5rem
}
.mt-9 {
    margin-top: 2.25rem
}
.block {
    display: block
}
.inline {
    display: inline
}
.flex {
    display: -webkit-flex;
    display: flex
}
.grid {
    display: grid
}
.hidden {
    display: none
}
.h-screen {
    height: 100vh
}
.h-full {
    height: 100%
}
.h-7 {
    height: 1.75rem
}
.w-36 {
    width: 9rem
}
.w-64 {
    width: 16rem
}
.w-80 {
    width: 20rem
}
.w-full {
    width: 100%
}
.max-w-lg {
    max-width: 32rem
}
.max-w-xl {
    max-width: 36rem
}
.max-w-sm {
    max-width: 24rem
}
.max-w-md {
    max-width: 28rem
}
.max-w-3xl {
    max-width: 48rem
}
.flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%
}
.cursor-pointer {
    cursor: pointer
}
.flex-row-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse
}
.flex-col {
    -webkit-flex-direction: column;
            flex-direction: column
}
.place-content-center {
    place-content: center
}
.items-center {
    -webkit-align-items: center;
            align-items: center
}
.justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end
}
.justify-center {
    -webkit-justify-content: center;
            justify-content: center
}
.justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between
}
.gap-2 {
    gap: 0.5rem
}
.gap-4 {
    gap: 1rem
}
.gap-8 {
    gap: 2rem
}
.border-0 {
    border-width: 0px
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}
.p-0 {
    padding: 0px
}
.p-4 {
    padding: 1rem
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
.py-0 {
    padding-top: 0px;
    padding-bottom: 0px
}
.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem
}
.text-center {
    text-align: center
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}
.font-normal {
    font-weight: 400
}
.font-semibold {
    font-weight: 600
}
.capitalize {
    text-transform: capitalize
}
.italic {
    font-style: italic
}
.text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity))
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity))
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity))
}
.underline {
    text-decoration: underline
}
.shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.filter {
    -webkit-filter: var(--tw-filter);
            filter: var(--tw-filter)
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.hover\:bg-transparent:hover {
    background-color: transparent
}
.hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity))
}
@media (min-width: 768px) {
    .md\:mt-20 {
        margin-top: 5rem
    }
}
@media (min-width: 1024px) {
    .lg\:block {
        display: block
    }
    .lg\:gap-24 {
        gap: 6rem
    }
}

